import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { properties } from "../../content/Properties"
import InnerPropertyPage from "../../components/molecules/InnerPropertyPage"

const slug = `17-leeds-ave-northfield-south-australia`

const post = properties.find(post => post.slug === slug) // Find the blog post by slug

const LeedsAveNorthfieldSouthAustralia = () => (
  <Layout>
    <Seo
      title="High Physical Support SDA Property in Northfield"
      description="Discover high physical support SDA housing in Morphett Vale. Designed for enhanced accessibility and comfort, ideal for participants seeking specialised care."
    />

    <InnerPropertyPage post={post} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default LeedsAveNorthfieldSouthAustralia
